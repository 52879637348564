module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-head/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.envato.com/blog"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blog-cms.envato.net/graphql","schema":{"perPage":175,"requestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"previewRequestConcurrency":5},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"BlockEditorContentNode":{"exclude":false},"BlockEditorPreview":{"exclude":false},"Category":{"excludeFieldNames":["ancestors","blocks","blocksJSON","children","contentNodes","parent","previewBlocks","previewBlocksJSON"]},"ContentType":{"exclude":false},"Comment":{"exclude":false},"Menu":{"exclude":false},"MenuItem":{"exclude":false},"Page":{"excludeFieldNames":["ancestors","blocks","blocksJSON","children","contentNodes","parent","previewBlocks","previewBlocksJSON"]},"Post":{"excludeFieldNames":["ancestors","blocks","blocksJSON","children","contentNodes","parent","previewBlocks","previewBlocksJSON"]},"PostFormat":{"exclude":false},"ReusableBlock":{"exclude":false},"Tag":{"excludeFieldNames":["ancestors","blocks","blocksJSON","children","contentNodes","parent","previewBlocks","previewBlocksJSON"]},"Taxonomy":{"exclude":false},"UserRole":{"exclude":false},"BlockAttributesObject":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
